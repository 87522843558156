import React from "react"

import ImagesLogos from "../../components/images/logos"

import "./logos.css"

export default function Logos() {
  return (
    <section id="logos" className="section-wrapper">
      <h1 className="section-title">Ils parlent de nous</h1>
      <main>
        <ImagesLogos />
      </main>
    </section>
  )
}
