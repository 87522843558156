import React from "react"

import AtTheBar from "../../components/images/ibahis-at-the-bar"

import "./history.css"

export default function History() {
  return (
    <section id="history" className="section-wrapper">
      <h1 className="section-title">Notre histoire</h1>
      <header>
        <aside>
          <AtTheBar />
        </aside>
        <p>
          "On en discutait et on se disait que si elle existait dans les magasins, cette boisson ferait un carton."
        </p>
      </header>
      <main className="text-history">
        <p>
          C’est l’histoire de mon enfance, un breuvage à la couleur rubis que me préparait ma mère.
          Une boisson fraîche, acidulée et gourmande, prônant des cuisines de ma grand-mère au Niger.
          J’avais l’habitude de la boire en Afrique lors de mes vacances estivales, mais également tout au long de l’année en France, notamment lors des grandes fêtes familiales.
        </p>
        <p>
          J’en parlais souvent à mes copines qui ont eu la chance, grâce à moi, de goûter à cette boisson. Puis lorsqu’on se promenait après l’école dans les magasins, on sillonnait les rayons dans l’espoir de la retrouver. Et je posais toujours la question à mes copines : « pourquoi je ne retrouve pas cette fameuse boisson que j’adore ? »
          On en discutait et on se disait que si elle existait dans les magasins, cette boisson ferait un carton !
        </p>
        <p>
          De là, j’ai grandi et j’ai décidé de donner sens à mon envie, mon histoire et mon rêve en créant moi-même ma madeleine de Proust, et en la partageant à tout le monde !<br />
          Donner le même plaisir aux gens dans une démarche de bien être, tout en répondant aux besoins d’évolution de notre génération : commerce équitable, développement durable, alimentation saine, innovation. Cette transmission de savoir-faire est une belle façon de remercier et de rendre hommage à ma mère et à toute ma communauté d’origine (africaine). Cette aventure m’a aidée à renforcer les liens avec le Niger et l’Afrique, berceau de mes origines, tout en affirmant ma modernité.
        </p>
      </main>
    </section>
  )
}