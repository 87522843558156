import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "./index-sections/home"
import News from "./index-sections/news"
import History from "./index-sections/history"
import Products from "./index-sections/products"
import Contact from "./index-sections/contact"
import About from "./index-sections/about"
import Logos from "./index-sections/logos"
import Cart from "../components/cart/cart"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="IBAHIS" lang="fr" />
      <Home />
      <News />
      <History />
      <Products />
      <About />
      <Contact />   
      <Logos /> 
      <Cart />  
  </Layout>
  )
}

export default IndexPage
