import React from "react"
import Price from "../../components/images/price"

import "./news.css"

export default function News() {
  return (
    <section id="news" className="section-wrapper">
      <h1 className="section-title">Actualités</h1>
      <main className="text-news">
      <p><small>Septembre 2022.</small><br />
      IBAHIS continue de séduir ! Nous avons désormais des points de vente sur Tinteniac, Orléans Centre, Toulouse, Paris 9 et Londres ! </p>
        <article>
            <h1>Fatimata Hamey et Rabaha Cosson Warou, Co-fondatrices de Ibahis sur le Studio Bang de la 7e édition de Bpifrance Inno Génération.</h1>
            <p><i>Le 07 octobre 2021</i></p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/i86PHfNQG74" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </article>
        <article>
            <h1>C'est avec fierté que nous faisons cette annonce : IBAHIS a remporté le prix Coups de Coeur des quartiers de Rennes 2021 par la ville de Rennes Métropole.</h1>
            <p><i>Juin 2021</i></p>
            <Price />
        </article>
      </main>
    </section>
  )
}