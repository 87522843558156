import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Price = () => {
  const data = useStaticQuery(graphql`
    query {
      price: file(relativePath: { eq: "sw3.jpg" }) {
        childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  if (!data?.price?.childImageSharp?.fixed) {
    return <div>Picture not found</div>
  }

  return <Img fixed={data.price.childImageSharp.fixed} alt="Prix remporté par IBAHIS en 2021" />
}

export default Price
