import React from "react"

import Fleurs from "../../components/images/flowers"
import Rabaha from "../../components/images/rabaha"

import "./about.css"

export default function About() {
  return (
    <section id="about" className="section-wrapper about">
      <h1 className="section-title">À propos</h1>
      <section className="provenance">
        <header>
          <p>
            "La fleur d’hibiscus est produite au Niger et cueillie par une coopérative de femmes, dans la région de Niamey."
          </p>
          <aside className="image-fleurs">
            <Fleurs />
          </aside>
        </header>
        <main>
          <h1>Provenance</h1>
          <p>
            L’hibiscus est une plante de type africaine qui traditionnellement était un produit sauvage dont la culture est en pleine expansion en Afrique et plus particulièrement au Niger. Il est de la famille des malvacées qu’il partage avec le coton et le gombo. L’hibiscus est résistant à la sécheresse en raison de son système racinaire pivotant qui lui permet d’explorer les couches profondes du sol en cas d’arrêt des pluies. La culture préfère les sols bien drainés et meubles. Il est intolérant aux sols engorgés. L’hibiscus s’accommode à des sols pauvres mais donne de meilleurs résultats en sols humides et fertiles (sols riches en matières organiques et en éléments nutritifs).
          </p>
          <p>
            La fleur d'hibiscus utilisée dans nos boissons est l'hibiscus Sabdariffa, caractérisé par la couleur des calices de type rouge qui est le plus apprécié pour les infusions, jus, confitures, gelées, etc.
            La fleur d’hibiscus est produite  au Niger et cueillie par
            une coopérative de femmes (villages environnants de Niamey).
            Son infusion se boit à tout moment de la journée, puisqu'elle ne contient pas de théine.  Elle plaît à toute la famille, des grands jusqu'aux petits, et s'accorde facilement avec de nombreux plats.
            Autre avantage; l'hibiscus est un antibactérien, anti-inflammatoire et antalgique. C'est un cholagogue, un diurétique et un relaxant des muscles utérins. Il est également hypotenseur, spasmolytique et hypocholestérolémiant.<br />
            Toutes nos les fleurs d'hibiscus sont récoltées au Niger et conditionnées en France.
          </p>
        </main>
      </section>

      <section className="ceo">
        <header>
          <aside className="image-ceo">
            <Rabaha />
          </aside>
          <p>
            "IBAHIS c'est bien plus qu'une infusion pétillante. C'est la promesse d'un monde moderne."
          </p>
        </header>
        <main>
          <h1>CEO {'&'} fondatrice de IBAHIS</h1>
          <p>
            <strong>Rabaha Cosson Warou et Fatimata Hamey</strong> sont déterminées à offrir une boisson exotique, chargée d’arômes naturels et biologiques bons pour le bien-être et la santé du consommateur. La sélection et l’originalité des produits entrant dans la composition de notre boisson respectent les normes environnementales strictes avec des méthodes de production sans utilisation d’engrais ou de produits chimiques. Toutes les étapes de l’élaboration de la boisson IBAHIS sont contrôlées et certifiées par des organismes indépendants français. <br />
            C’est notre marque de fabrique que de proposer une boisson saine, novatrice et à haute valeur nutritive. La boisson IBAHIS est le début d’une aventure prometteuse entre la Bretagne et l’Afrique.
          </p>
        </main>
      </section>
    </section>
  )
}