import React from "react"

import Canette from "../../components/images/cans-mix"

import "./home.css"

export default function Home() {
  return (
    <section id="home">
      <p>
        <p>
          <strong>La boisson pétillante aux mille vertus !</strong>
        </p>
        "De l’eau, des bulles et des plantes.<br />
        Pourquoi se priver d’un plaisir simple et efficace ? <br />
        <span className="red-color">IBAHIS</span> est la nouvelle boisson qui vous permettra d’allier plaisir festif et bien-être avec sa couleur naturellement violacée et son gout subtilement fruité et acidulé. <br />
        Un régal pour les grands et les petits."
      </p>
      <aside>
        <Canette />
      </aside>
    </section>
  )
}